import React from 'react';
import { Link } from 'react-router-dom';
import service from '../../../api/service';
import './hero.css';
import { customStyleGenerator } from '../../../utils/customStyle';

const Hero = (props) => {
  let texts;
  let image;

  if (props) {
    texts = props.data[0].texts.map((item, index) => {
      const customStyle = customStyleGenerator(item.textStyle);

      if (item.type === 'TEXT_FIELD' && item.name === 'HEADER') {
        return (
          <h4 key={index} className={item.name} style={customStyle}>
            {item.value}
          </h4>
        );
      } else if (item.type === 'TEXT_FIELD' && item.name === 'SUB_HEADER') {
        return (
          <h4 key={index} className={item.name} style={customStyle}>
            {item.value}
          </h4>
        );
      } else if (item.type === 'BUTTON') {
        var landingPageId = props.landingPageIdParam;
        if (
          props?.url?.includes('www.') ||
          props?.url?.includes('http://') ||
          props?.url?.includes('https://')
        ) {
          return (
            <a
              className={'HERO-BUTTON'}
              key={index}
              href={props.url}
              target='_blank'
              rel='noreferrer'
              style={customStyle}
            >
              {item.value}
            </a>
          );
        }
        return (
          <Link
            className={'HERO-BUTTON'}
            to={'/' + props.url}
            key={index}
            onClick={() => {
              service.fireClickAction(landingPageId);
            }}
            style={customStyle}
          >
            {item.value}
          </Link>
        );
      }
    });
    if (props.data[0].image !== null)
      image = (
        <img
          className='hero_image'
          src={props.data[0].image.src}
          alt={props.data[0].image.name}
          loading='lazy'
        />
      );
  }

  return (
    <div className={props ? props.layout : ''} id={props.blockId}>
      <div className={'text-containers'}>{texts}</div>
      {image}
    </div>
  );
};

export default Hero;
