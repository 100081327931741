import React from 'react';
import './PreviousButton.css';
import { useLocation, useNavigate } from 'react-router-dom';

export default function PreviousButton(props) {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <button
      className='previous-button'
      onClick={() => {
        props.clearSelectedAnswer();
        navigate(`/${props.previousQuestionUrl}`);
      }}
      disabled={location.pathname === `/${props.previousQuestionUrl}`}
    >
      <svg
        width='14'
        height='14'
        viewBox='0 0 14 14'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M13.1585 6.01717C13.1012 6.00787 13.0433 6.00358 12.9853 6.00434H3.10854L3.32391 5.90417C3.53442 5.80453 3.72593 5.66893 3.88986 5.50349L6.65954 2.73381C7.02431 2.3856 7.08561 1.82543 6.80479 1.40657C6.47795 0.96022 5.85118 0.863306 5.4048 1.19014C5.36874 1.21656 5.33446 1.24536 5.30225 1.27635L0.293786 6.28481C-0.0976257 6.67578 -0.0979701 7.31001 0.293003 7.70142C0.293253 7.70167 0.293535 7.70195 0.293786 7.7022L5.30225 12.7107C5.69397 13.1013 6.3282 13.1004 6.71886 12.7087C6.7496 12.6779 6.7783 12.6451 6.80479 12.6105C7.08561 12.1916 7.02431 11.6315 6.65954 11.2833L3.89487 8.50857C3.7479 8.36144 3.57893 8.23808 3.39402 8.14295L3.09352 8.00772H12.9301C13.4418 8.02672 13.8908 7.66927 13.9869 7.1663C14.0755 6.62022 13.7046 6.10576 13.1585 6.01717Z'
          fill='#7E7E7E'
        />
      </svg>
    </button>
  );
}
