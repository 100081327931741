import { useMutation } from '@tanstack/react-query';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../../api/post';
import useLocalStorage from '../../hooks/useLocalStorage';
import Loader from '../Loader/Loader';
import PreviousButton from './previousButton/PreviousButton';
import { QuestionRenderer } from './questionRenderer/QuestionRenderer';
import './lead.css';

const SaveConversionFunnelResponse = 'save-conversion-funnel-response';

const Lead = (props) => {
  const { data, next, questionUrls, isContactCollection } = props;
  const navigate = useNavigate();
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [loading, setLoading] = useState(false);
  const [response, setResponse, clearResponse] = useLocalStorage(SaveConversionFunnelResponse, {});
  const [previousQuestionAnswer, setPreviousQuestionAnswer, clearPreviousResponse] =
    useLocalStorage('previous-answer', []);

  const { mutateAsync } = useMutation({
    mutationFn: (params) => api.post('/saveConversionFunnelResponse', params),
    onSuccess: () => {
      clearResponse();
      setLoading(false);
    },
    onError: (err) => {
      console.error(err);
      setLoading(false);
    },
  });

  const handleNextQuestion = async () => {
    const kickOutAnswers = data.answers
      ?.filter((answer) => answer.kickOutAnswer)
      ?.map((kickoutMessage) => kickoutMessage.answerDescription);

    setLoading(true);
    await mutateAsync(response).catch((err) => console.error(err));

    if (kickOutAnswers.includes(selectedAnswer)) {
      navigate('/' + data?.kickOutUrl);
      clearPreviousResponse();
    } else {
      setSelectedAnswer(null);
      if (next?.url) navigate('/' + next?.url);
      else if (isContactCollection) navigate(`/${isContactCollection}`);
      else {
        clearPreviousResponse();
        navigate('/finished');
      }
    }
  };

  function handleAnswerSelect(answer) {
    setResponse({
      answer,
      conversionFunnelId: props.conversionFunnelId,
      conversionFunnelType: props.conversionFunnelType,
      informationToCollect: null,
      kickOutAnswer:
        data.answers.find((i) => i.answerDescription === answer).kickOutAnswer === answer,
      orderOfQuestion: data.order,
      question: data.questionDescription,
      questionId: data.id,
      sessionUserId: 'random uuid',
    });
    setPreviousQuestionAnswer((prev) =>
      prev.filter((i) => i?.id !== data.id).concat({ id: data.id, answer }),
    );
    setSelectedAnswer(answer);
  }

  // Render the current question and answers
  const RenderCurrentQuestion = useMemo(
    () =>
      QuestionRenderer[data.answerType]({
        selectValue: handleAnswerSelect,
        data,
        selectedAnswer:
          selectedAnswer || previousQuestionAnswer.find((i) => i.id === data.id)?.answer,
      }),
    [data.answers, selectedAnswer, response],
  );

  return (
    <div className={'lead-wrapper'} style={loading ? { justifyContent: 'center' } : {}}>
      {loading ? (
        <>
          <Loader />
          <p className='loader_text'>Loading ...</p>
        </>
      ) : (
        <>
          <PreviousButton
            {...{
              previousQuestionUrl:
                questionUrls[questionUrls.findIndex((i) => i === data.url) - 1] || data.url,
              clearSelectedAnswer: () => setSelectedAnswer(null),
            }}
          />
          {RenderCurrentQuestion}
          <button
            className={
              selectedAnswer || previousQuestionAnswer.find((i) => i.id === data.id)?.answer
                ? 'active-btn'
                : 'disabled-btn'
            }
            disabled={
              !selectedAnswer && !previousQuestionAnswer.find((i) => i.id === data.id)?.answer
            }
            onClick={handleNextQuestion}
          >
            Next
          </button>
        </>
      )}
    </div>
  );
};

export default Lead;
