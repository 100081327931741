import React from 'react';
import './misc1.css';
import { customStyleGenerator } from '../../../../utils/customStyle';

const Misc1 = (props) => {
  let containerContent;

  if (props) {
    containerContent = props.data.map((item, index) => {
      return (
        <div key={index}>
          {item.texts.map((info, index) => {
            const customStyle = customStyleGenerator(info.textStyle);
            return (
              <div key={index}>
                {info.type === 'BUTTON' ? (
                  <div key={index} className={'misc-button-01'}>
                    <button style={customStyle}>{info.value}</button>
                  </div>
                ) : (
                  <h4 key={index} className={info.name} style={customStyle}>
                    {info.value}
                  </h4>
                )}
              </div>
            );
          })}
        </div>
      );
    });
  }

  return (
    <div className={'misc-container-01'} id={props.blockId}>
      <h1>FAQ</h1>
      {containerContent}
    </div>
  );
};

export default Misc1;
