import { useQuery } from '@tanstack/react-query';
import api from '../api/post';

const useGetDefaultLandingPage = (landingPageTemplateTypeParam) =>
  useQuery({
    queryKey: ['getDefaultLandingPage', landingPageTemplateTypeParam],
    queryFn: () =>
      api.get('/getDefaultLandingPage', {
        params: {
          templateType: landingPageTemplateTypeParam,
        },
      }),
    select: sortDefaultLandingPageResponse,
    enabled: !!landingPageTemplateTypeParam,
  });

function sortDefaultLandingPageResponse(response) {
  return response.data.blocks.sort((a, b) =>
    a.orderNo > b.orderNo ? 1 : b.orderNo > a.orderNo ? -1 : 0,
  );
}

export default useGetDefaultLandingPage;
