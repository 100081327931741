import React from 'react';
import Features from './features/features';
import Footer from './footer/footer';
import Hero from './hero/hero';
import HowItWorks from './how-it-works/howItWorks';
import Misc from './misc/misc';
import Pricing from './pricing/pricing';
import TopNavigation from './top-navigation/topNavigation';
import Trust from './trust/trust';

const Blocks = (props) => {
  return blockChooser(props);
};

export default Blocks;

function blockChooser(props) {
  switch (props.block) {
    case 'TOP_NAVIGATION':
      return (
        <TopNavigation
          layout={props.layout}
          data={props.data}
          url={props.url}
          blockId={props.block}
        />
      );
    case 'HERO_BLOCK':
      return (
        <Hero
          layout={props.layout}
          data={props.data}
          landingPageIdParam={props.landingPageIdParam}
          url={props.url}
          blockId={props.block}
        />
      );
    case 'HOW_BLOCK':
      return (
        <HowItWorks layout={props.layout} data={props.data} url={props.url} blockId={props.block} />
      );
    case 'FEATURE':
      return (
        <Features layout={props.layout} data={props.data} url={props.url} blockId={props.block} />
      );
    case 'QUOTE':
      return (
        <Trust layout={props.layout} data={props.data} url={props.url} blockId={props.block} />
      );
    case 'PRICING':
      return (
        <Pricing layout={props.layout} data={props.data} url={props.url} blockId={props.block} />
      );
    case 'MISC_BLOCK':
      return <Misc layout={props.layout} data={props.data} url={props.url} blockId={props.block} />;
    case 'FOOTER':
      return (
        <Footer layout={props.layout} data={props.data} url={props.url} blockId={props.block} />
      );
    default:
      return null;
  }
}
