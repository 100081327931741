import { useQuery } from '@tanstack/react-query';
import api from '../api/post';

const useGetLandingPageById = (landingPageIdParams) =>
  useQuery({
    queryKey: ['getLandingPageById', landingPageIdParams],
    queryFn: () =>
      api.get('/getLandingPageById', {
        params: {
          landingPageId: landingPageIdParams,
        },
      }),
    select: sortLandingPageByIdResponse,
    enabled: !!landingPageIdParams,
  });

function sortLandingPageByIdResponse(response) {
  return response.data.lpJsonStructure.blocks.sort((a, b) =>
    a.orderNo > b.orderNo ? 1 : b.orderNo > a.orderNo ? -1 : 0,
  );
}

export default useGetLandingPageById;
