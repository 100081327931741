import { useQuery } from '@tanstack/react-query';
import React from 'react';
import api from '../api/post';

function sortQuestions(response) {
  return response?.data.questions.sort((a, b) =>
    a.order > b.order ? 1 : b.order > a.order ? -1 : 0,
  );
}

const useGetConversionFunnel = (conversionFunnelId) => {
  const queryInfo = useQuery({
    queryKey: ['getConversionFunnel', conversionFunnelId],
    queryFn: () =>
      api.get('/getConversionFunnel', {
        params: {
          id: conversionFunnelId,
        },
      }),
    enabled: !!conversionFunnelId,
  });

  const sortedQuestions = React.useMemo(() => sortQuestions(queryInfo?.data), [queryInfo.data]);

  return {
    ...queryInfo,
    extraData: {
      question: sortedQuestions,
      firstProductUrl: queryInfo.data?.data.firstProductUrl,
      firstUrl: sortedQuestions?.[0]?.url,
      conversionFunnelId: queryInfo.data?.data.conversionFunnelId,
      conversionFunnelType: queryInfo.data?.data.conversionFunnelType,
      contactInformation: queryInfo.data?.data.contactInformation,
      formSubmissionMessage: queryInfo.data?.data.formSubmissionMessage,
    },
  };
};

export default useGetConversionFunnel;
