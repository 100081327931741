import { Turn as Hamburger } from 'hamburger-react';
import React, { useRef, useState } from 'react';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import useScrollDirection from '../../../../hooks/useScrollDirection';
import BurgerMenu from '../BurgerMenu/BurgerMenu';
import './top02.css';

const Top1 = (props) => {
  const links = props?.data?.[0]?.links || [];
  const isDesktop = useMediaQuery('(min-width: 960px)');
  const scrollDirection = useScrollDirection();

  const navigationBar = useRef(null);
  const [open, setOpen] = useState(false);

  return (
    <>
      <header
        className={`top-02-container ${scrollDirection === 'down' ? 'fadeOutUp' : 'fadeInDown'}`}
        id={props.blockId}
        ref={navigationBar}
      >
        {props?.data?.[0]?.image && (
          <img
            className='top-02-container-image'
            src={props.data[0].image.src || props.data[0].image.defaultSrc}
            alt={props?.data[0]?.image?.name}
          />
        )}
        {links?.length ? (
          isDesktop ? (
            <div className={'menu-items'}>
              {links?.map((link, index) => (
                <a className='menu_item_link' key={index} href={`#${link.linkToInternally}`}>
                  {link?.value}
                </a>
              ))}
            </div>
          ) : (
            <Hamburger
              {...{
                toggle: () => setOpen(!open),
                toggled: open,
                color: '#000',
                size: 24,
              }}
            />
          )
        ) : null}
      </header>
      {open && links?.length && (
        <BurgerMenu
          {...{
            open,
            setOpen,
            links: props?.data?.[0]?.links || [],
            topGap: navigationBar?.current?.clientHeight,
          }}
        />
      )}
    </>
  );
};

export default Top1;
