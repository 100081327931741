import React, { useEffect, useMemo } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import useGetConversionFunnel from './api/useGetConversionFunnel';
import useGetDefaultLandingPage from './api/useGetDefaultLandingPage';
import useGetLandingPage from './api/useGetLandingPage';
import useGetLandingPageById from './api/useGetLandingPageById';
import Blocks from './components/blocks/blocks';
import ContactCollection from './components/lead/contactCollection/ConctactCollection';
import Kickout from './components/lead/kickout/kickout';
import Lead from './components/lead/lead';
import LeadLastPage from './components/lead/leadLastPage';
import Loader from './components/Loader/Loader';
import './App.css';
import ReactPixel from 'react-facebook-pixel';
import useGetPixelId from './api/useGetPixelId';

const conversionFunnelTypes = {
  LEAD_QUALIFICATION: 'LEAD_QUALIFICATION',
  COLLECT_CONTACT: 'COLLECT_CONTACT',
};

function App() {
  // let subdomain = window.location.hostname?.split('.')?.slice(0, -2)?.join('.');
  // if (process.env.NODE_ENV === 'development') subdomain = 'localhost';
  let subdomain = window.location.hostname?.split('.')[0];

  const urlParams = new URLSearchParams(window.location.search);
  const landingPageIdParams = urlParams.get('landingPageId');
  const landingPagePersonaParams = urlParams.get('personaId');
  const landingPageTemplateTypeParam = urlParams.get('landingPageTemplateType');

  const { data: getDefaultLandingPageResponse, isLoading: isGetDefaultLandingPageResponseLoading } =
    useGetDefaultLandingPage(landingPageTemplateTypeParam);
  const { data: getLandingPageByIdResponse, isLoading: isGetLandingPageByIdResponseLoading } =
    useGetLandingPageById(landingPageIdParams);
  const {
    data: getLandingPage,
    isLoading: isGetLandingPageLoading,
    conversionFunnelId: conversionFunnelIdFromLandingPage,
    landingPageId,
  } = useGetLandingPage({
    landingPagePersonaParams,
    subdomain,
  });

  const {
    extraData: {
      conversionFunnelId,
      conversionFunnelType,
      firstUrl,
      firstProductUrl,
      question,
      contactInformation,
      formSubmissionMessage,
    },
  } = useGetConversionFunnel(conversionFunnelIdFromLandingPage);

  const { data: pixelObject } = useGetPixelId(landingPagePersonaParams);

  const content = useMemo(() => {
    if (getDefaultLandingPageResponse) {
      return getDefaultLandingPageResponse;
    } else if (getLandingPageByIdResponse) {
      return getLandingPageByIdResponse;
    } else if (getLandingPage) {
      return getLandingPage;
    }
  }, [getDefaultLandingPageResponse, getLandingPageByIdResponse, getLandingPage]);

  useEffect(() => {
    window.addEventListener('beforeunload', () => {
      localStorage.removeItem('save-conversion-funnel-response');
      localStorage.removeItem('previous-answer');
    });
    return window.removeEventListener('beforeunload', () => {
      localStorage.removeItem('save-conversion-funnel-response');
      localStorage.removeItem('previous-answer');
    });
  }, []);

  useEffect(() => {
    ReactPixel.init(pixelObject?.data ?? '', {}, { autoConfig: true, debug: true });
    return ReactPixel.pageView();
  }, []);

  if (
    isGetDefaultLandingPageResponseLoading &&
    isGetLandingPageByIdResponseLoading &&
    isGetLandingPageLoading
  ) {
    return (
      <div className='loader_wrapper'>
        <Loader />
        <p className='loader_text'>Loading...</p>
      </div>
    );
  }

  return (
    <>
      <BrowserRouter>
        <Routes>
          {Object.values(conversionFunnelTypes).includes(conversionFunnelType) &&
            question?.map((item, index, array) => {
              return (
                <Route
                  key={index}
                  path={item.url}
                  element={
                    <Lead
                      data={item}
                      next={question.length > index ? question[index + 1] : ''}
                      questionUrls={array.map((i) => i.url)}
                      conversionFunnelType={conversionFunnelType}
                      conversionFunnelId={conversionFunnelId}
                      isContactCollection={contactInformation?.path}
                    />
                  }
                ></Route>
              );
            })}
          {Object.values(conversionFunnelTypes).includes(conversionFunnelType) &&
            question?.map((item, index) => {
              return (
                <Route
                  key={index}
                  path={item.kickOutUrl}
                  element={<Kickout data={item} fullUrl={window.location.href} />}
                ></Route>
              );
            })}
          <Route
            path='/finished'
            element={
              <LeadLastPage
                firstProductUrl={firstProductUrl}
                formSubmissionMessage={formSubmissionMessage}
              />
            }
          ></Route>
          {contactInformation && (
            <Route
              path={contactInformation.path || '/contact-info'}
              element={
                <ContactCollection
                  {...{ contactInformation, conversionFunnelType, conversionFunnelId }}
                />
              }
            ></Route>
          )}
          <Route
            path='/*'
            element={
              <div className='App'>
                {content?.map((item, index) => {
                  return (
                    <Blocks
                      key={index}
                      block={item.blockType}
                      layout={item.blockName}
                      data={item.items}
                      landingPageIdParam={landingPageId}
                      personaIdParam={landingPagePersonaParams}
                      url={
                        conversionFunnelType === 'NONE'
                          ? firstProductUrl
                          : firstUrl || contactInformation?.path || firstProductUrl
                      }
                    />
                  );
                })}
              </div>
            }
          ></Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
