import React from 'react';
import smile from '../../../assets/smile.png';
import './kickout.css';

const Kickout = (props) => {
  return (
    <div className={'kickout-wrapper'}>
      <div className={'kickout-wrapper-box'}>
        <img src={smile} alt={'success'} />
        <h2>Ouch!</h2>
        <p>{props.data.kickOutMessage}</p>
        <a href={props.fullUrl}>Back to page</a>
      </div>
    </div>
  );
};

export default Kickout;
