import React from 'react';
import './top1.css';

const Top1 = (props) => {
  let containerContent;

  if (props) {
    containerContent = props.data.map((item, index) => {
      if (item.image !== null)
        return (
          <div key={index} className={'top-01-container'}>
            <img src={item.image.src} alt='' />
          </div>
        );
    });
  }
  return <>{containerContent}</>;
};

export default Top1;
