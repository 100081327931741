import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation } from '@tanstack/react-query';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as z from 'zod';
import api from '../../../api/post.js';
import Loader from '../../Loader/Loader';
import FormBuilderContactInfo, {
  contactInfoFields,
} from './FormBuilderContactInfo/FormBuilderContactInfo';
import './ContactInfo.css';

const phoneRegex = new RegExp(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/);
const stringRegex = new RegExp(/^[a-zA-Z]+$/);

const contactCollectSchema = z
  .object({
    [contactInfoFields.firstName]: z
      .string()
      .regex(stringRegex, 'Please insert only letters.')
      .nonempty('First Name is required'),
    [contactInfoFields.lastName]: z
      .string()
      .regex(stringRegex, 'Please insert only letters.')
      .nonempty('Last Name is required'),
    [contactInfoFields.email]: z.string().email('Please enter a valid email'),
    [contactInfoFields.phoneNumber]: z
      .string()
      .regex(phoneRegex, 'Please enter a valid phone number'),
  })
  .partial();

export default function ContactCollection(props) {
  const { contactInformation, conversionFunnelType, conversionFunnelId } = props;
  const navigate = useNavigate();

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: (params) => api.post('/saveConversionFunnelResponse', params),
  });

  const formMethods = useForm({
    defaultValues: contactInformation?.informationToCollect?.reduce(
      (acc, item) => ({ ...acc, [item]: null }),
      {},
    ),
    resolver: zodResolver(contactCollectSchema),
  });

  const onSubmit = async (data) => {
    for (let key in data) {
      let value = data[key];
      await mutateAsync({
        answer: value,
        conversionFunnelId,
        conversionFunnelType,
        informationToCollect: key,
        kickOutAnswer: false,
        orderOfQuestion: 1,
        question: key,
        questionId: contactInformation.id,
        // TODO: Add sessionUserId
        sessionUserId: 'random uuid',
      });
    }
    navigate('/finished');
  };

  return (
    <div className='FORM_WRAPPER'>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)} className='FORM'>
          {isLoading ? (
            <>
              <Loader />
              <p className='loader_text'>Loading ...</p>
            </>
          ) : (
            <>
              <p className='FORM_TITLE'>Contact Info Collection</p>
              {contactInformation?.informationToCollect?.map((item) =>
                FormBuilder({ key: item, field: item }),
              )}
              <button
                type='submit'
                className='FORM_SUBMIT_BUTTON'
                disabled={!formMethods.formState.isValid}
              >
                Submit
              </button>
            </>
          )}
        </form>
      </FormProvider>
    </div>
  );
}

const FormBuilder = ({ field, props }) => {
  switch (field) {
    case contactInfoFields.firstName:
      return <FormBuilderContactInfo.FIRST_NAME {...props} />;
    case contactInfoFields.lastName:
      return <FormBuilderContactInfo.LAST_NAME {...props} />;
    case contactInfoFields.email:
      return <FormBuilderContactInfo.EMAIL {...props} />;
    case contactInfoFields.phoneNumber:
      return <FormBuilderContactInfo.PHONE_NUMBER {...props} />;
    default:
      return <div></div>;
  }
};
