import React from 'react';
import './BurgerMenu.css';

export default function BurgerMenu({ open, setOpen, topGap, links }) {
  return (
    <div
      className={`burger_menu_overlay fadeInDown`}
      style={{ top: topGap }}
      onClick={() => setOpen(!open)}
    >
      <div className='burger_menu'>
        {links?.map((link, index) => (
          <span className='burger_menu_item' key={index}>
            <a href={`#${link.linkToInternally}`}>{link.value}</a>
          </span>
        ))}
      </div>
    </div>
  );
}
