import React from 'react';
import { useFormContext } from 'react-hook-form';
import camelCase from '../../../../utils/camelCase';

export const contactInfoFields = {
  firstName: 'FIRST_NAME',
  lastName: 'LAST_NAME',
  email: 'EMAIL',
  phoneNumber: 'PHONE_NUMBER',
};

const contactInfoPlaceHolder = {
  FIRST_NAME: 'John',
  LAST_NAME: 'Doe',
  EMAIL: 'johndoe@domain.com',
  PHONE_NUMBER: '+1 123-456-7890',
};

const FormBuilderContactInfo = {};

FormBuilderContactInfo[contactInfoFields.firstName] = () => {
  const { register, formState } = useFormContext();

  const error = formState.errors[contactInfoFields.firstName];

  return (
    <section className='FORM_INPUT_WRAPPER'>
      <label htmlFor={contactInfoFields.firstName} className='FORM_INPUT_LABEL'>
        {camelCase(contactInfoFields.firstName)}
      </label>
      <input
        id={contactInfoFields.firstName}
        className='FORM_INPUT_INPUT'
        type='text'
        {...register(contactInfoFields.firstName, { required: true })}
        placeholder={contactInfoPlaceHolder[contactInfoFields.firstName]}
      />
      {error && (
        <span className='FORM_INPUT_ERROR_ALERT' role='alert'>
          {error.message}
        </span>
      )}
    </section>
  );
};

FormBuilderContactInfo[contactInfoFields.lastName] = () => {
  const { register, formState } = useFormContext();

  const error = formState.errors[contactInfoFields.lastName];
  return (
    <section className='FORM_INPUT_WRAPPER'>
      <label htmlFor={contactInfoFields.lastName} className='FORM_INPUT_LABEL'>
        {camelCase(contactInfoFields.lastName)}
      </label>
      <input
        id={contactInfoFields.lastName}
        className='FORM_INPUT_INPUT'
        type='text'
        {...register(contactInfoFields.lastName, { required: true })}
        placeholder={contactInfoPlaceHolder[contactInfoFields.lastName]}
      />
      {error && (
        <span className='FORM_INPUT_ERROR_ALERT' role='alert'>
          {error.message}
        </span>
      )}
    </section>
  );
};

FormBuilderContactInfo[contactInfoFields.email] = () => {
  const { register, formState } = useFormContext();

  const error = formState.errors[contactInfoFields.email];
  return (
    <section className='FORM_INPUT_WRAPPER'>
      <label htmlFor={contactInfoFields.email} className='FORM_INPUT_LABEL'>
        {camelCase(contactInfoFields.email)}
      </label>
      <input
        id={contactInfoFields.email}
        className='FORM_INPUT_INPUT'
        {...register(contactInfoFields.email, { required: true })}
        type='email'
        placeholder={contactInfoPlaceHolder[contactInfoFields.email]}
      />
      {error && (
        <span className='FORM_INPUT_ERROR_ALERT' role='alert'>
          {error.message}
        </span>
      )}
    </section>
  );
};

FormBuilderContactInfo[contactInfoFields.phoneNumber] = () => {
  const { register, formState } = useFormContext();

  const error = formState.errors[contactInfoFields.phoneNumber];
  return (
    <section className='FORM_INPUT_WRAPPER'>
      <label htmlFor={contactInfoFields.phoneNumber} className='FORM_INPUT_LABEL'>
        {camelCase(contactInfoFields.phoneNumber)}
      </label>
      <input
        id={contactInfoFields.phoneNumber}
        className='FORM_INPUT_INPUT'
        {...register(contactInfoFields.phoneNumber, { required: true })}
        type='tel'
        placeholder={contactInfoPlaceHolder[contactInfoFields.phoneNumber]}
      />
      {error && (
        <span className='FORM_INPUT_ERROR_ALERT' role='alert'>
          {error.message}
        </span>
      )}
    </section>
  );
};

export default FormBuilderContactInfo;
