import React from 'react';
import './trust1.css';
import { customStyleGenerator } from '../../../../utils/customStyle';

const Trust1 = (props) => {
  let containerContent;

  if (props) {
    containerContent = props.data.map((item) => {
      return item.texts.map((info, index) => {
        const customStyle = customStyleGenerator(info.textStyle);

        return (
          <h4 key={index} className={info.name} style={customStyle}>
            {info.value}
          </h4>
        );
      });
    });
  }

  return (
    <div id={props.blockId} className={'quote-container-full'}>
      <div className='right-apostrophe'>
        <img src='https://staircasedigitalbuckett.s3.amazonaws.com/landingPageImages/right-apostrophe.png'></img>
      </div>
      <div className={'quote-container'}>
        <div className='above-apostrophe'>
          <img src='https://staircasedigitalbuckett.s3.amazonaws.com/landingPageImages/right-apostrophe.png'></img>
        </div>
        {containerContent}
      </div>
      <div className='left-apostrophe'>
        <img src='https://staircasedigitalbuckett.s3.amazonaws.com/landingPageImages/left-apostrophe.png'></img>
      </div>
    </div>
  );
};

export default Trust1;
