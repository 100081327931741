import { useQuery } from '@tanstack/react-query';
import React from 'react';
import api from '../api/post';

const useGetLandingPage = ({ landingPagePersonaParams, subdomain }) => {
  const queryInfo = useQuery({
    queryKey: ['getLandingPage', landingPagePersonaParams, subdomain],
    queryFn: () =>
      api.get('/getLandingPage', {
        params: {
          personaId: landingPagePersonaParams,
          subDomain: subdomain,
        },
      }),
    enabled: !!landingPagePersonaParams,
  });
  return {
    ...queryInfo,
    data: React.useMemo(
      () =>
        queryInfo.data?.data?.lpJsonStructure?.blocks.sort((a, b) =>
          a.orderNo > b.orderNo ? 1 : b.orderNo > a.orderNo ? -1 : 0,
        ),
      [queryInfo.data],
    ),
    landingPageId: queryInfo.data?.data.id,
    conversionFunnelId: queryInfo.data?.data.conversionFunnelId,
  };
};

export default useGetLandingPage;
