import React from 'react';
import { Link } from 'react-router-dom';
import './pricingSingleProduct.css';
import { customStyleGenerator } from '../../../../utils/customStyle';

const PricingSingleProduct = (props) => {
  let header;
  let price;
  let unList = [];
  let unListContent;
  let button;
  let image;

  if (props) {
    props.data[0].texts.map((item, index) => {
      const customStyle = customStyleGenerator(item.textStyle);
      if (item.name === 'HEADER') {
        header = (
          <h4 key={index} className={item.name} style={customStyle}>
            {item.value}
          </h4>
        );
      } else if (item.name === 'PRICE') {
        price = (
          <h3 key={index} className={item.name} style={customStyle}>
            {item.value}
          </h3>
        );
      } else if (item.name === 'PRICE_DESCRIPTION') {
        unList.push(item);
      } else if (item.type === 'BUTTON') {
        if (
          props?.url?.includes('www.') ||
          props?.url?.includes('http://') ||
          props?.url?.includes('https://')
        ) {
          button = (
            <a key={index} href={props.url} target='_blank' rel='noreferrer' style={customStyle}>
              {item.value}
            </a>
          );
        }

        button = (
          <Link to={'/' + props.url} key={index} style={customStyle}>
            {item.value}
          </Link>
        );
      }
    });
    if (props.data[0].image !== null)
      image = <img src={props.data[0].image.src} alt={props.data[0].image.name} />;

    if (unList) {
      unListContent = unList.map((item, index) => {
        return <ul key={index}>{item.value}</ul>;
      });
    }
  }
  return (
    <div className={'PRICING_01_SINGLE_PROD'} id={props.blockId}>
      <div className={'text-containers'}>
        <div className={'price-containers'}>
          {header}
          {price}
          <div>{unListContent}</div>
        </div>
        {button}
      </div>
      {image}
    </div>
  );
};

export default PricingSingleProduct;
