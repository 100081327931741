import React from 'react';
import './footer.css';
import { customStyleGenerator } from '../../../utils/customStyle';

let copy = '©';
const Footer = (props) => {
  let header;

  if (props) {
    props.data[0].texts.map((item, index) => {
      const customStyle = customStyleGenerator(item.textStyle);
      if (item.name === 'HEADER') {
        header = (
          <h4 key={index} className={item.name} style={customStyle}>
            {copy} {item.value}
          </h4>
        );
      }
    });
  }

  return (
    <div className={props ? props.layout : ''} id={props.blockId}>
      <div className={'how-triple-flex'}>{header}</div>
    </div>
  );
};

export default Footer;
