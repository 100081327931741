import React from 'react';
import PricingMultipleProduct from './pricing-multiple-product/pricingMultipleProduct';
import PricingSingleProduct from './pricing-single-product/pricingSingleProduct';

const Pricing = (props) => {
  let container;

  if (props) {
    switch (props.layout) {
      case 'PRICING_01_SINGLE_PROD':
        container = (
          <PricingSingleProduct data={props.data} url={props.url} blockId={props.blockId} />
        );
        break;
      case 'PRICING_04_MULTI_PROD':
        container = (
          <PricingMultipleProduct data={props.data} url={props.url} blockId={props.blockId} />
        );
        break;
      default:
        return null;
    }
  }

  return <>{container}</>;
};

export default Pricing;
