import React from 'react';
import Select from 'react-select';

export const QuestionRenderer = {};

QuestionRenderer['DROPDOWN'] = (props) => {
  const {
    selectValue,
    data: { questionDescription, answers },
  } = props;
  return (
    <div className='question-wrapper' style={{ overflow: 'visible' }}>
      <h1>{questionDescription}</h1>
      <Select
        className='dropdown'
        classNamePrefix={'dropdown'}
        options={answers?.map((item) => ({
          value: item.answerDescription,
          label: item.answerDescription,
        }))}
        placeholder={'Choose your answer'}
        onChange={(e) => selectValue(e.value)}
      />
    </div>
  );
};

QuestionRenderer['MULTIPLE_CHOICE'] = (props) => {
  const {
    selectValue,
    selectedAnswer,
    data: { questionDescription, answers },
  } = props;

  return (
    <div className='question-wrapper'>
      <h1>{questionDescription}</h1>
      <ul className='multiple-choice-list'>
        {answers.map((item, index) => {
          return (
            <li
              key={index}
              onClick={() => selectValue(item.answerDescription)}
              className={
                selectedAnswer === item.answerDescription
                  ? 'multiple-choice-list-item item-active'
                  : 'multiple-choice-list-item'
              }
            >
              <input
                id={item.answerDescription}
                className='multiple-choice-input'
                type='radio'
                checked={selectedAnswer === item.answerDescription ? true : false}
                onChange={() => selectValue(item.answerDescription)}
              />
              <label htmlFor={item.answerDescription} className='multiple-choice-label'>
                {item.answerDescription}
              </label>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
