import React from 'react';
import './leadLastPage.css';

const LeadLastPage = (props) => {
  return (
    <div className='finished-question-wrapper'>
      <div className={'finished-question'}>
        <svg
          width='101'
          height='100'
          viewBox='0 0 101 100'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <circle cx='50.5' cy='50' r='50' fill='#F2F2F2' />
          <path
            d='M59.68 30C66.46 30 71 34.76 71 41.84V58.182C71 65.24 66.46 70 59.68 70H42.34C35.56 70 31 65.24 31 58.182V41.84C31 34.76 35.56 30 42.34 30H59.68ZM59.36 44C58.68 43.32 57.56 43.32 56.88 44L48.62 52.26L45.12 48.76C44.44 48.08 43.32 48.08 42.64 48.76C41.96 49.44 41.96 50.54 42.64 51.24L47.4 55.98C47.74 56.32 48.18 56.48 48.62 56.48C49.08 56.48 49.52 56.32 49.86 55.98L59.36 46.48C60.04 45.8 60.04 44.7 59.36 44Z'
            fill='#77BA4F'
          />
        </svg>
        <div>
          <h1>{props.formSubmissionMessage || 'Thank You'}</h1>
          <p>Your submission has been received!</p>
        </div>
      </div>
      <a href={props.firstProductUrl} target='_blank' rel='noreferrer'>
        Go to Product
      </a>
    </div>
  );
};

export default LeadLastPage;
