import React from 'react';
import Top1 from '../top-navigation/top-01/top1';
import Top02 from '../top-navigation/top-02/top02';
import './topNavigation.css';

const TopNavigation = (props) => {
  let container;

  if (props) {
    switch (props.layout) {
      case 'TOP_NAV_01':
        container = <Top1 data={props.data} />;
        break;
      case 'TOP_NAV_01_SERVICE':
        container = <Top02 data={props.data} personaId={props.personaId} blockId={props.blockId} />;
        break;
      default:
        return null;
    }
  }

  return <>{container}</>;
};

export default TopNavigation;
