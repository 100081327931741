import api from './post';

const Service = {
  fireClickAction: function (landingPageId) {
    api
      .put('/conversion-action', null, {
        params: {
          id: landingPageId,
        },
      })
      .catch(function (error) {
        console.error(error);
      });
  },
};

export default Service;
