import React from 'react';
import Trust1 from './trust-01/trust1';

const Trust = (props) => {
  let container;

  if (props) {
    switch (props.layout) {
      case 'TRUST_01_SINGLE_QUOTE':
        container = <Trust1 data={props.data} blockId={props.blockId} />;
        break;
      default:
        return null;
    }
  }

  return <>{container}</>;
};

export default Trust;
