import React from 'react';
import { Link } from 'react-router-dom';
import './pricingMultipleProduct.css';
import { customStyleGenerator } from '../../../../utils/customStyle';

const MultipleSingleProduct = (props) => {
  let header;
  let prices = [];
  let button;
  let pricesContainer;

  if (props) {
    for (let i = 0; i < props.data.length; i++) {
      if (props.data[i].texts[0].name === 'HEADER') {
        const customStyle = customStyleGenerator(props.data[i].texts[0].textStyle);
        header = (
          <h4 key={i} className={props.data[i].texts[0].name} style={customStyle}>
            {props.data[i].texts[0].value}
          </h4>
        );
      } else if (props.data[i].texts[0].name === 'MAIN_CTA') {
        const customStyle = customStyleGenerator(props.data[i].texts[0].textStyle);
        if (
          props?.url?.includes('www.') ||
          props?.url?.includes('http://') ||
          props?.url?.includes('https://')
        ) {
          button = (
            <a key={i} href={props.url} target='_blank' rel='noreferrer' style={customStyle}>
              {props.data[i].texts[0].value}
            </a>
          );
        }

        button = (
          <Link to={'/' + props.url} key={i} style={customStyle}>
            {props.data[i].texts[0].value}
          </Link>
        );
      } else {
        prices.push(props.data[i]);
      }
    }

    pricesContainer = prices.map((item, index) => {
      let priceHeader;
      let priceDescriptionUnList = [];
      let price;
      item.texts.map((item, index) => {
        const customStyle = customStyleGenerator(item.textStyle);
        if (item.name === 'PRICE_HEADER') {
          priceHeader = (
            <h4 key={index} className={item.name} style={customStyle}>
              {item.value}
            </h4>
          );
        } else if (item.name === 'PRICE') {
          price = (
            <h2 key={index} className={item.name} style={customStyle}>
              {item.value}
            </h2>
          );
        } else if (item.name === 'PRICE_DESCRIPTION') {
          priceDescriptionUnList.push(item);
        }
      });
      return (
        <div key={index} className={'package-container'}>
          {priceHeader}
          <div className={'package-description'}>
            <ul className={'package-description-list'}>
              {priceDescriptionUnList.map((item, index) => {
                const customStyle = customStyleGenerator(item.textStyle);
                return (
                  <li key={index} style={customStyle}>
                    {item.value}
                  </li>
                );
              })}
            </ul>
            {price}
          </div>
        </div>
      );
    });
  }
  return (
    <div className={'PRICING_04_MULTI_PROD'} id={props.blockId}>
      {header}
      <div className={'prices-multiple-container'}>{pricesContainer}</div>
      {button}
    </div>
  );
};

export default MultipleSingleProduct;
