import React from 'react';
import './Loader.css';

export default function Loader() {
  return (
    <>
      <svg
        width='48'
        height='48'
        viewBox='0 0 48 48'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className='loader'
      >
        <path
          d='M24 4V12M24 36L24 44M12 24H4M44 24L36 24M38.1569 38.1569L32.5 32.5M38.1569 9.99988L32.5 15.6567M9.84315 38.1569L15.5 32.5M9.84315 9.99988L15.5 15.6567'
          stroke='#0F293D'
          strokeWidth='3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </>
  );
}
