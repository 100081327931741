import React from 'react';
import How1 from './how-01/how1';

const HowItWorks = (props) => {
  let container;

  if (props) {
    switch (props.layout) {
      case 'HIW_01_3STEPS':
        container = <How1 data={props.data} url={props.url} blockId={props.blockId} />;
        break;
      default:
        return null;
    }
  }

  return <>{container}</>;
};

export default HowItWorks;
