import { useQuery } from '@tanstack/react-query';
import api from '../api/post';

const useGetPixelId = (personaId) =>
  useQuery({
    queryKey: ['dataSource', personaId],
    queryFn: () =>
      api.get('/dataSource', {
        params: {
          personaId,
        },
      }),
    enabled: !!personaId,
  });

export default useGetPixelId;
