import React from 'react';
import { Link } from 'react-router-dom';
import './features.css';
import { customStyleGenerator } from '../../../utils/customStyle';

function Features(props) {
  let header;
  let button;
  const featuresList = [];
  let featuresContainer;

  for (let i = 0; i < props.data.length; i++) {
    if (props.data[i].texts[0].name === 'HEADER') {
      const customStyle = customStyleGenerator(props.data[i].texts[0].textStyle);
      header = (
        <h4 key={i} className={props.data[i].texts[0].name} style={customStyle}>
          {props.data[i].texts[0].value}
        </h4>
      );
    } else if (props.data[i].texts[0].name === 'MAIN_CTA') {
      const customStyle = customStyleGenerator(props.data[i].texts[0].textStyle);
      if (
        props?.url?.includes('www.') ||
        props?.url?.includes('http://') ||
        props?.url?.includes('https://')
      ) {
        button = (
          <a
            className={'HERO-BUTTON'}
            href={props.url}
            target='_blank'
            rel='noreferrer'
            style={customStyle}
          >
            {props.data[i].texts[0].value}
          </a>
        );
      }
      button = (
        <Link to={`/${props.url}`} key={i} style={customStyle}>
          {props.data[i].texts[0].value}
        </Link>
      );
    } else {
      featuresList.push(props.data[i]);
    }
  }

  featuresContainer = featuresList.map((item, index) => (
    <div key={index} className='feature-block'>
      <img src={item.image.src} />
      <div className='feature-description'>
        {item.texts.map((item, index) => {
          const customStyle = customStyleGenerator(item.textStyle);
          return (
            <h4 key={index} className={item.name} style={customStyle}>
              {item.value}
            </h4>
          );
        })}
      </div>
    </div>
  ));

  return (
    <div className='feature-container' id={props.blockId}>
      {header}
      <div className='multiple-feature-container'>
        <div className='features-list'>{featuresContainer}</div>
      </div>
      {button}
    </div>
  );
}

export default Features;
