import React from 'react';
import { Link } from 'react-router-dom';
import './how1.css';
import { customStyleGenerator } from '../../../../utils/customStyle';

const How1 = (props) => {
  let headerContent;
  let containerContent;
  let buttonsContent;
  let headerArray;
  let containerArray;
  let buttonsArray;

  if (props) {
    headerArray = [];
    containerArray = [];
    buttonsArray = [];
    for (let i = 0; i < props.data.length; i++) {
      if (props.data[i].texts[0].name === 'HEADER') {
        headerArray.push(props.data[i]);
      } else if (props.data[i].texts[0].name === 'MAIN_CTA') {
        buttonsArray.push(props.data[i]);
      } else {
        containerArray.push(props.data[i]);
      }
    }
    headerContent = headerArray.map((item, index) => {
      return (
        <div key={index}>
          {item.texts.map((info, index) => {
            const customStyle = customStyleGenerator(info.textStyle);
            return (
              <h4 key={index} className={info.name} style={customStyle}>
                {info.value}
              </h4>
            );
          })}
        </div>
      );
    });
    containerContent = containerArray.map((item, index) => {
      if (item.image !== null) {
        return (
          <div key={index} className={'how-triple-container'}>
            <img src={item.image.src} />
            {item.texts.map((info, index) => {
              const customStyle = customStyleGenerator(info.textStyle);
              return (
                <h4 key={index} className={info.name} style={customStyle}>
                  {info.value}
                </h4>
              );
            })}
          </div>
        );
      } else {
        return (
          <div key={index} className={'how-triple-container'}>
            {item.texts.map((info, index) => {
              const customStyle = customStyleGenerator(info.textStyle);
              return (
                <h4 key={index} className={info.name} style={customStyle}>
                  {info.value}
                </h4>
              );
            })}
          </div>
        );
      }
    });
  }

  buttonsContent = buttonsArray.map((item, index) => {
    const customStyle = customStyleGenerator(item.textStyle);
    if (
      props?.url?.includes('www.') ||
      props?.url?.includes('http://') ||
      props?.url?.includes('https://')
    ) {
      return (
        <a
          style={customStyle}
          className={'HERO-BUTTON'}
          key={index}
          href={props.url}
          target='_blank'
          rel='noreferrer'
        >
          {item.texts[index].value}
        </a>
      );
    }

    return (
      <Link to={'/' + props.url} key={index} style={customStyle}>
        {item.texts[index].value}
      </Link>
    );
  });

  return (
    <div id={props.blockId} className={'how-it-works-wrapper-01'}>
      {headerContent}
      <div className={'how-triple-flex'}>{containerContent}</div>
      {buttonsContent}
    </div>
  );
};

export default How1;
